import "./Onboarding.scss";
import thrivingPetsLogo from "../../assets/icons/thrivingpets-logo-dark.svg";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import Spinner from "../../components/common/Spinner/Spinner";
import { checkCoupon, applyCouponDiscount } from "../../api/chargebee";
import {
  OnboardingHeader,
  OnboardingMenus,
  TermsAndConditions,
  ServiceAggreement,
  ServiceAggreementNZ,
  ModalAddingPractice,
  arrowLeftIcon,
  dogAndCat,
  trashIcon,
  editIcon,
  vetXtendLogo,
} from "./Module";
import { thrivingPetsLogoDarkReverse } from "../Dashboard/Module";
import {
  createCustomer,
  createSubscriptions,
  getCustomer,
  getPaymentMethods,
  subscribeAPlan,
  updateBillingAddress,
} from "../../api/chargebee";
import { useFormik } from "formik";
import { Row, Modal } from "../../components/layout";
import { Button } from "../../components/common";
import { TextInput } from "../../components/input";
import { ApplicationState } from "../../store";
import { DeleteReason } from "../../types/pet";
import { actionCreators as userActionCreators } from "../../store/User";
import { actionCreators as commonActionCreators } from "../../store/Common";
import history from "../../utils/history";

const imageEndpoint = process.env.REACT_APP_BACKEND_IMAGE_ENDPOINT;
const API = process.env.REACT_APP_BACKEND_API || "";

const PaymentCompletion: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const { fetchUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const { changeActionType } = bindActionCreators(
    commonActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const [reloaded, setReloaded] = useState(false);
  const [validCard, setValidCard] = useState(false);
  const [checkedAccount, setCheckedAccount] = useState(false);
  const [estimateDiscount, setEstimateDiscount] = useState<number | null>(null);
  const [createdAccount, setCreatedAccount] = useState(false);
  const [showChargebeeModal, setShowChargebeeModal] = useState(false);
  const [chargebeePaymentUrl, setChargebeePaymentUrl] = useState("");
  const [calculation, setCalculation] = useState<any>({
    //frequency: "yearly",
    price: 299,
    gst: user?.address?.country != "Australia" ? 15 : 10,
    //country_code: user?.address?.country != "Australia" ? "NZ" : "AU",
    currency: user?.address?.country != "Australia" ? "NZD" : "AUD",
    coupon: null,
    coupon_applied: false,
    discount: 0,
    discount_percentage: 0,
    amount: 0,
    addon: "none",
    option: "vetxpress",
  });
  const [show, setShow] = useState({
    couponDiscount: false,
    aggreements: false,
    termsConditions: false,
    subscriptionPlan:
      user?.subscription_plan || user?.subscription_status == "active"
        ? true
        : false,
  });
  const [agreed, setAgreed] = useState({
    plan: false,
    serviceAggreement: false,
    representative: false,
    authorise: false,
    terms: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const applyCoupon = async () => {
    //return setEstimateDiscount(10);
    setShow({ ...show, couponDiscount: true });
    await applyCouponDiscount(calculation).then((r) => {
      console.log({ coup: r });
      setIsLoading(false);
      if (!r || !r.data) return toast.error(`Invalid discount code`);
      const { discount_type: dt, discount_percentage: dp } = r.data;
      let { amount } = calculation;
      if (dt == "percentage") {
        amount = amount - (dp / 100) * amount;
      }
      setCalculation({
        ...calculation,
        amount,
        discount_percentage: dp,
        coupon_applied: true,
      });
      setShow({ ...show, couponDiscount: false });
    });
  };
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding/");
    if (!user?.bank_account_name || user?.bank_account_name == "")
      return history.push("/onboarding/bank-information");
    (async () => {
      setIsLoading(true);
      const customer = await getCustomer();
      console.log({ customer, calculation });
      if (customer?.data?.errors?.length > 0) {
        return customer.data.errors.map((e: any) => {
          toast.error(e);
        });
      }
      if (customer.status !== 200)
        return toast.error(`Unable to fetch data please refresh the page!`);
      const url = await getPaymentMethods();
      console.log({ url });
      if (!url) return toast.error(`Not able to retrieve Payment Methods`);
      setIsLoading(false);
      return setChargebeePaymentUrl(url);
    })();
    const { price, gst } = calculation;
    const amount = price + (price * gst) / 100;
    setCalculation({ ...calculation, amount });
    console.log({ user, gst });
  }, [isAuthenticated]);
  useEffect(() => {
    (async () => {
      await fetchUserData();
    })();
  }, [isAuthenticated]);
  const paymentCompletion = () => {
    return (
      <div className="content">
        <OnboardingHeader step={3} stepTotal={3} />
        <div className="content-box checkout-plan">
          <OnboardingMenus active="payment-completion" />
          <h2 className="title-2">My partnership level will be:</h2>
          <h3 className="title-2">Select A Plan:</h3>
          <ul className="select-a-plan">
            <li>
              <div className="radio-wrap">
                <span
                  className={
                    calculation.option == "vetxpress"
                      ? "checkbox active"
                      : "checkbox"
                  }
                  onClick={() => {
                    const { gst, discount_percentage: dp } = calculation;
                    const price = 299;
                    const amount = price + (price * gst) / 100;
                    setCalculation({
                      ...calculation,
                      option: "vetxpress",
                      price,
                      amount: amount - (dp / 100) * amount,
                      addon: "none",
                    });
                  }}
                ></span>
              </div>
              <div className="wrap">
                <div className="list-wrap">
                  <div className="image">
                    <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/vet-express-medium.png" />
                  </div>
                  <div className="pricing">
                    <div className="price-wrap">
                      <span className="price">
                        <span className="currency">$</span>299.00
                      </span>
                      <span className="price-desc">plus gst annually</span>
                    </div>
                    <span className="frequency">Plan Paid Yearly</span>
                  </div>
                </div>
                <div
                  className={
                    calculation.option == "vetxpress"
                      ? "table-wrap"
                      : "table-wrap none"
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        <td>Partnership Program & Rebates</td>
                        <td>Practice Performance Reporting</td>
                        <td>
                          Digital Marketing Service <br />
                          <span
                            style={{ fontWeight: "normal", fontSize: "16px" }}
                          >
                            (Education & Retail Communications)
                          </span>
                        </td>
                        <td>TOTAL PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                        </td>
                        <td>
                          <strong>$299</strong> <br />
                          annually
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ height: "auto", background: "#fff" }}
                          colSpan={4}
                        >
                          All prices are in {calculation?.currency}. GST not
                          included in prices.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li>
              <div className="radio-wrap">
                <span
                  className={
                    calculation.option == "vetxplore"
                      ? "checkbox active"
                      : "checkbox"
                  }
                  onClick={() => {
                    const { gst, discount_percentage: dp } = calculation;
                    const price = 250;
                    const amount = price + (price * gst) / 100;
                    setCalculation({
                      ...calculation,
                      option: "vetxplore",
                      price,
                      amount: amount - (dp / 100) * amount,
                      addon: "none",
                    });
                  }}
                ></span>
              </div>
              <div className="wrap">
                <div className="list-wrap">
                  <div className="image">
                    <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/vet-explore-medium.png" />
                  </div>
                  <div className="pricing">
                    <div className="price-wrap">
                      <span className="price">
                        <span className="currency">$</span>250.00
                      </span>
                      <span className="price-desc">plus gst per month</span>
                    </div>
                    <span className="frequency">Plan Paid Monthly</span>
                  </div>
                </div>
                <div
                  className={
                    calculation.option == "vetxplore"
                      ? "table-wrap"
                      : "table-wrap none"
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        <td>Partnership Program & Rebates</td>
                        <td>Practice Performance Reporting</td>
                        <td>
                          Digital Marketing Service <br />
                          <span
                            style={{ fontWeight: "normal", fontSize: "16px" }}
                          >
                            (Education & Retail Communications)
                          </span>
                        </td>
                        <td>TOTAL PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-close.png" />
                        </td>
                        <td>
                          <strong>$250</strong> <br />
                          monthly
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ height: "auto", background: "#fff" }}
                          colSpan={4}
                        >
                          All prices are in {calculation?.currency}. GST not
                          included in prices.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li>
              <div className="radio-wrap">
                <span
                  className={
                    calculation.option == "vetxpand"
                      ? "checkbox active"
                      : "checkbox"
                  }
                  onClick={() => {
                    const { gst, discount_percentage: dp } = calculation;
                    const price = 500;
                    const amount = price + (price * gst) / 100;
                    setCalculation({
                      ...calculation,
                      option: "vetxpand",
                      price,
                      amount: amount - (dp / 100) * amount,
                      addon: "none",
                    });
                  }}
                ></span>
              </div>
              <div className="wrap">
                <div className="list-wrap">
                  <div className="image">
                    <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/vet-expand-medium.png" />
                  </div>
                  <div className="pricing">
                    <div className="price-wrap">
                      <span className="price">
                        <span className="currency">$</span>500.00
                      </span>
                      <span className="price-desc">plus gst annually</span>
                    </div>
                    <span className="frequency">Plan Paid Yearly</span>
                  </div>
                </div>
                <div
                  className={
                    calculation.option == "vetxpand"
                      ? "table-wrap"
                      : "table-wrap none"
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        <td>Partnership Program & Rebates</td>
                        <td>Practice Performance Reporting</td>
                        <td>
                          Digital Marketing Service <br />
                          <span
                            style={{ fontWeight: "normal", fontSize: "16px" }}
                          >
                            (Education & Retail Communications)
                          </span>
                        </td>
                        <td>TOTAL PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <img src="https://vetxtend.com.au/wp-content/uploads/2024/04/icon-check.png" />
                        </td>
                        <td>
                          <strong>$500</strong> <br />
                          annually
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            height: "auto",
                            background: "#9284bf",
                            color: "#fff",
                          }}
                          colSpan={4}
                        >
                          <strong>
                            Select VetXpand Marketing Bundle Add-ons:
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{ padding: 0, verticalAlign: "top" }}
                        >
                          <div className="vetxpand-add-ons">
                            <div style={{ padding: "20px" }}>
                              <img
                                src="https://vetxtend.com.au/wp-content/uploads/2024/03/img-vetxpand3.png"
                                style={{ maxWidth: "100%" }}
                              />
                              <p style={{ fontSize: "14px", width: "180px" }}>
                                If you choose the VetXpand partnership level,
                                you can add on our Core Marketing Bundle. This
                                is not available with the VetXpress or VetXplore
                                partnership levels
                              </p>
                            </div>
                            <div className="desc-wrap">
                              <div className="desc header">
                                <div>
                                  <p>
                                    <strong>
                                      Core Marketing Bundle Add-On
                                    </strong>
                                    <br />
                                    (Only available with the vetXpand
                                    partnership level)
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <strong>Price</strong>
                                    <br />
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <strong>
                                      Add-on
                                      <br />
                                      Bundle
                                    </strong>
                                  </p>
                                </div>
                              </div>
                              <div className="desc">
                                <div>
                                  <p>
                                    <strong>
                                      Core Marketing emails include: {""}
                                    </strong>
                                    NPS Customer Satisfaction Surveys, New
                                    Client Welcome, We Miss You (Lapsed
                                    Patients), Pet Birthday, Lifestage
                                    Communications (Puppy, Kitten, Adolescent,
                                    Adult, Senior and Geriatric) + up to 4
                                    others of your choice sent to your entire
                                    database.
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <strong>$199</strong>
                                    <br />
                                    monthly
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        className={
                                          calculation.addon == "core"
                                            ? "checkbox active"
                                            : "checkbox"
                                        }
                                        onClick={() => {
                                          const {
                                            discount_percentage: dp,
                                            gst,
                                            addon: ao,
                                          } = calculation;
                                          const addon =
                                            ao == "core" ? undefined : "core";
                                          const price = !addon ? 500 : 699;
                                          const amount =
                                            price + (price * gst) / 100;
                                          console.log({ calculation });
                                          setCalculation({
                                            ...calculation,
                                            addon,
                                            amount:
                                              amount - (dp / 100) * amount,
                                          });
                                        }}
                                      ></span>
                                    </div>
                                  </p>
                                </div>
                              </div>
                              <div className="desc">
                                <div>
                                  <p>
                                    <strong>
                                      Reminder Bundle Add-On: {""}
                                    </strong>
                                    Cat and dog desexing, vaccination, 6-monthly
                                    senior, annual and overdue reminders.
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <strong>$299</strong>
                                    <br />
                                    monthly
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        className={
                                          calculation.addon == "reminder"
                                            ? "checkbox active"
                                            : "checkbox"
                                        }
                                        onClick={() => {
                                          const {
                                            discount_percentage: dp,
                                            gst,
                                            addon: ao,
                                          } = calculation;
                                          const addon =
                                            ao == "reminder"
                                              ? undefined
                                              : "reminder";
                                          const price = !addon ? 500 : 799;
                                          const amount =
                                            price + (price * gst) / 100;
                                          console.log({ calculation });
                                          setCalculation({
                                            ...calculation,
                                            addon,
                                            amount:
                                              amount - (dp / 100) * amount,
                                          });
                                        }}
                                      ></span>
                                    </div>
                                  </p>
                                </div>
                              </div>
                              <p>
                                All prices are in {calculation?.currency}. GST
                                not included in prices.
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
          </ul>
          <h3 className="title-2">TOTAL</h3>
          <ul className="checkout-calculation checkout-total">
            <li className="justify-center">
              <div className="flex" style={{ alignItems: "center" }}>
                <div
                  className="flex"
                  style={{ alignItems: "center", flexDirection: "row" }}
                >
                  <span className="currency">$</span>
                  <h2 className="title-1 big" style={{ margin: "0" }}>
                    {calculation?.amount?.toFixed(2)}
                  </h2>
                </div>
                <span className="currency">
                  Due Today <br />
                  Incl. GST
                </span>
              </div>
              <p className="flex">
                {calculation?.frequency == "monthly" ? (
                  <p style={{ lineHeight: "30px" }}>
                    Your nominated payment method will be charge on
                    <br />
                    the {new Date().getDate()}
                    <sup style={{ fontSize: "12px" }}>th</sup> of each month.
                  </p>
                ) : (
                  <>
                    {calculation?.addon != "none" ? (
                      <p style={{ lineHeight: "30px" }}>
                        Your nominated payment method will be charged
                        <br />
                        on the {""} {new Date().getDate()}
                        <sup style={{ fontSize: "12px" }}>th</sup>
                        {""} of each month for your monthly payments.
                        <br />
                        Your nominated payment method will be charged on
                        <br />
                        this day each year for your annual payment.
                      </p>
                    ) : (
                      <p style={{ lineHeight: "30px" }}>
                        Your nominated payment method will be charged on
                        <br />
                        this date next year.
                      </p>
                    )}
                  </>
                )}
              </p>
            </li>
            <li>
              <p
                className="flex"
                style={{
                  display: "nonex",
                  textAlign: "right",
                  width: "100%",
                }}
              >
                <div>
                  {!calculation?.coupon_applied ? (
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-text"
                        value={calculation?.coupon}
                        onChange={(e) => {
                          const coupon = e.currentTarget.value;
                          setCalculation({ ...calculation, coupon });
                        }}
                      />
                      <input
                        style={{ cursor: "pointer" }}
                        type="button"
                        className="input-btn"
                        value={!isLoading ? "Apply Coupon" : "Loading"}
                        onClick={async (v) => {
                          setIsLoading(true);
                          await applyCoupon();
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      Discount Code: {""}
                      <span className="coupon-wrap">{calculation?.coupon}</span>
                    </>
                  )}
                </div>
              </p>
            </li>
          </ul>
          <ul className="checkout-options">
            <li>
              <span
                className={
                  agreed.representative
                    ? "checkbox active square"
                    : "checkbox square"
                }
                onClick={() => {
                  setAgreed({
                    ...agreed,
                    representative: !agreed.representative,
                  });
                }}
              ></span>
              <p>I am an authorised representative of the business</p>
            </li>
            <li>
              <span
                className={
                  agreed.authorise
                    ? "checkbox active square"
                    : "checkbox square"
                }
                onClick={() => {
                  setAgreed({ ...agreed, authorise: !agreed.authorise });
                }}
              ></span>
              <p>
                I have authority from the directors/owners (as applicable) of
                the business, which authority has not been revoked, to enter
                into this agreement and for the business to perform the
                transactions contemplated by it.
              </p>
            </li>
            <li>
              <span
                className={
                  agreed.serviceAggreement
                    ? "checkbox active square"
                    : "checkbox square"
                }
                onClick={() => {
                  setAgreed({
                    ...agreed,
                    serviceAggreement: !agreed.serviceAggreement,
                  });
                }}
              ></span>
              <p>
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    setShow({ ...show, aggreements: true });
                  }}
                >
                  I have read the Reporting and Marketing Services Agreement and
                  accompanying terms and conditions and agree to enter into, and
                  be legally bound by, the agreement and the terms and
                  conditions.
                </a>
                <br />
                <span
                  style={{
                    color: "#222",
                  }}
                >
                  Click to read to the Reporting and Marketing Services
                  Agreement
                </span>
              </p>
            </li>
          </ul>
          {show.termsConditions && (
            <Modal
              isOpen={true}
              closeModal={() => setShow({ ...show, termsConditions: false })}
            >
              <TermsAndConditions />
            </Modal>
          )}
          {show.aggreements && (
            <Modal
              isOpen={true}
              closeModal={() => setShow({ ...show, aggreements: false })}
            >
              {window.location.host != "app.vetxtend.co.nz" ? (
                <>
                  <ServiceAggreement user={user} isPaying={true} />
                  <TermsAndConditions />
                </>
              ) : (
                <>
                  <ServiceAggreementNZ user={user} isPaying={true} />
                  <TermsAndConditions app="NZ" />
                </>
              )}
            </Modal>
          )}
        </div>
        {calculation && (
          <>
            <p
              style={{ width: "500px", maxWidth: "100%" }}
              className="bottom-action text-center flex"
            >
              <Button
                label="Enter Payment Information"
                isDisabled={isLoading}
                customClass="button btn btn-lg btn-green"
                onClick={async () => {
                  setShowChargebeeModal(true);
                }}
              />
              <Button
                label="Start Partnership"
                isDisabled={isLoading}
                customClass="button btn btn-lg btn-purple"
                onClick={async () => {
                  console.log({ agreed, calculation });
                  if (!agreed.serviceAggreement)
                    return toast.error(
                      "You must agree with the Services Agreement"
                    );
                  if (!agreed.representative)
                    return toast.error(
                      "You must be an Authorise Representative"
                    );
                  if (!agreed.authorise)
                    return toast.error(
                      "You must have a power to authorise transaction"
                    );
                  setIsLoading(true);
                  const { option: plan, addon, coupon } = calculation;
                  const subscribe = await subscribeAPlan(
                    plan,
                    user?.practice_id,
                    addon,
                    coupon
                  ).then((r) => {
                    setIsLoading(false);
                    return r;
                  });
                  if (!subscribe || !subscribe.data)
                    toast.error("Network Error Please try again.");
                  if (subscribe?.data?.errors?.length > 0) {
                    return subscribe.data.errors.map((e: any) => {
                      toast.error(e);
                    });
                  }
                  toast.success("Successfully paid the plan!");
                  console.log({ subscribe });
                  return history.push("/onboarding/order-reference/");
                }}
              />
            </p>
            <p
              style={{ padding: "20px", textAlign: "center", fontSize: "22px" }}
            >
              Need to subscribe more than one practice? You’ll have the option
              <br />
              to add more practices once your first subscription is finalised.
            </p>
          </>
        )}
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {paymentCompletion()}
      <Modal
        maxWidth={600}
        isOpen={showChargebeeModal}
        closeModal={() => setShowChargebeeModal(false)}
      >
        <div className="dashboard">
          <div className="content-header">
            <div className="content-body">
              {chargebeePaymentUrl ? (
                <iframe
                  src={chargebeePaymentUrl}
                  style={{
                    width: "100%",
                    minHeight: "550px",
                    background: "#999",
                    overflow: "hidden",
                    borderRadius: "5px",
                  }}
                ></iframe>
              ) : (
                <p style={{ lineHeight: "30px", margin: "20px" }}>
                  PLEASE WAIT WHILE
                  <br />
                  FETCHING PAYMENT METHODS...
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        maxWidth={500}
        isOpen={user?.status == "validation"}
        closeModal={() => {
          history.push("/onboarding/");
        }}
      >
        <div className="onboarding">
          <div className="content-header">
            <div className="content-body">
              <img
                src="https://vetxtend.com.au/wp-content/uploads/2024/01/logo2.png"
                style={{
                  margin: "20px auto",
                  display: "block",
                }}
              />
              <p style={{ lineHeight: "30px", margin: "20px" }}>
                You need to validate your email address before you can continue.
                You should have received an email from us with a verification
                link to click. Please check your junk folder.
              </p>
              <p style={{ lineHeight: "30px", margin: "20px" }}>
                Once you've clicked on the link in the email, press the "Try
                {""} Again" button below.
              </p>
              <button
                className="button btn btn-purple"
                style={{ margin: "auto" }}
                onClick={async () => {
                  window.location.reload();
                }}
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={show.subscriptionPlan}
        closeModal={() => console.log(`disable close`)}
        maxWidth={400}
        maxHeight={280}
        disablePadding
      >
        <div style={{ padding: "20px" }}>
          <p
            style={{
              fontSize: "24px",
              textAlign: "center",
              color: "#51429a",
            }}
          >
            You currently have an
            <br />
            active subcription!
            <br />
            <br />
            <div style={{ display: "flex", columnGap: "10px" }}>
              <a
                className="btn btn-green"
                style={{ minWidth: "120px" }}
                onClick={() => history.push("/onboarding/order-reference")}
              >
                Cancel
              </a>
              <a
                className="btn btn-purple"
                style={{ minWidth: "150px" }}
                onClick={() => {
                  setShow({ ...show, subscriptionPlan: false });
                }}
              >
                Proceed & Upgrade
              </a>
            </div>
          </p>
        </div>
      </Modal>
      <ModalAddingPractice />
    </div>
  );
};

export default PaymentCompletion;
